import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=69acd0d4&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&lang=less&"
import style1 from "./detail.vue?vue&type=style&index=1&id=69acd0d4&lang=less&scoped=true&"
import style2 from "./detail.vue?vue&type=style&index=2&id=69acd0d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69acd0d4",
  null
  
)

export default component.exports