<!--
 * @Author: huangcheng
 * @Date: 2020-09-09 16:43:48
 * @Description: 任务详情
-->
<template>
  <section class="business-detail">
    <a-menu style="width: 126px;height: 100%" mode="vertical" :defaultSelectedKeys="[type]" @click="handleMenuClick">
      <a-menu-item key="3">任务详情</a-menu-item>
      <a-menu-item key="1">工作者名单</a-menu-item>
      <a-menu-item key="2">任务结果</a-menu-item>
      <!-- <a-menu-item key="4">付款管理</a-menu-item> -->
    </a-menu>
    <section class="detail-content">
      <!-- 报名名单 -->
      <div v-show="type === '1'">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24" style="margin-bottom:20px">
              <a-col :lg="8" :md="8" :sm="12">
                <a-form-item label="签约状态">
                  <a-select style="width: 200px" placeholder="请选择状态" v-model="searchParams.signStatus" >
                    <a-select-option value="10">未确认</a-select-option>
                    <a-select-option value="20">已确认</a-select-option>
                    <a-select-option value="30">已签约</a-select-option>
                    <a-select-option value="91">取消确认-工作者取消报名</a-select-option>
                    <a-select-option value="92">确认失败-企业拒绝（不合适）</a-select-option>
                    <a-select-option value="93">拒绝签约失败-工作者拒绝</a-select-option>
                    <a-select-option value="94">取消签约失败-在未同意的情况下取消签约（收回签约）</a-select-option>
                    <a-select-option value="95">超时签约失败-超时系统自动签约失败</a-select-option>
                    <a-select-option value="96">签约失败-企业取消任务</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="8" :sm="12">
                <a-form-item label="工作状态">
                  <a-select style="width: 200px" placeholder="请选择状态" v-model="searchParams.workStatus">
                    <a-select-option value="10">未开始</a-select-option>
                    <a-select-option value="20">工作中-未提交任务结果</a-select-option>
                    <a-select-option value="21">工作中-已提交任务结果</a-select-option>
                    <a-select-option value="22">工作中-审核通过</a-select-option>
                    <a-select-option value="23">工作中-审核不通过</a-select-option>
                    <a-select-option value="30">工作完成</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="handleToSearchScope">查询</a-button>
                  <a-button style="margin-left: 8px" icon="reload" @click="resetSearchScope">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <s-table
          ref="table"
          size="default"
          :rowKey="(record) => record.id"
          :columns="tableColumnsBm"
          :data="loadAccountListBm"
          bordered
        >
        </s-table>
      </div>
      <!-- 任务结果 -->
      <div v-show="type === '2'">
        <!-- <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col :lg="8" :md="8" :sm="12">
                <a-form-item label="工作状态">
                  <a-select style="width: 100%" placeholder="请选择状态" v-model="searchParamsJg.workStatus">
                    <a-select-option value="10">未开始</a-select-option>
                    <a-select-option value="20">工作中-未提交任务结果</a-select-option>
                    <a-select-option value="21">工作中-已提交任务结果</a-select-option>
                    <a-select-option value="22">工作中-以审核通过</a-select-option>
                    <a-select-option value="22">工作中-以审核不通过</a-select-option>
                    <a-select-option value="30">工作完成</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="handleToSearchScopeJg">查询</a-button>
                  <a-button style="margin-left: 8px" icon="reload" @click="resetSearchScopeJg">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div> -->
        <s-table
          ref="tableJg"
          size="default"
          :rowKey="(record) => record.id"
          :columns="tableColumnsJg"
          :data="loadAccountListJg"
          bordered
        >
          <div slot="items" slot-scope="text, record">
            <a @click="showPic(record)">查看</a>
              <a-modal
                title="结果查看"
                :visible="visiblePicture"
                :footer="null"
                @cancel="visiblePicture=false"
              >
                <ul class="pic-select">
                  <li v-for="(item,index) in picList" :key="index"><a :href="item.fileUrl" target="_blank">{{item.fileName===null?`文件${index+1}`:item.fileName}}</a></li>
                </ul>
                <!-- <a-carousel @goTo="goTo" arrows>
                  <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1"
                    :dots='position'
                  >
                    <a-icon type="left-circle" />
                  </div>
                  <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                    <a-icon type="right-circle" />
                  </div>
                  <div v-for="(item,index) in picList" :key="index">
                    <img v-if="item.fileUrl.indexOf('png')!==-1||item.fileUrl.indexOf('jpg')!==-1||item.fileUrl.indexOf('jpeg')!==-1||item.fileUrl.indexOf('JPEG')!==-1||item.fileUrl.indexOf('bmp')!==-1||item.fileUrl.indexOf('tif')!==-1||item.fileUrl.indexOf('gif')!==-1||item.fileUrl.indexOf('pcx')!==-1||item.fileUrl.indexOf('tga')!==-1||item.fileUrl.indexOf('exif')!==-1||item.fileUrl.indexOf('fpx')!==-1||item.fileUrl.indexOf('svg')!==-1||item.fileUrl.indexOf('psd')!==-1||item.fileUrl.indexOf('cdr')!==-1||item.fileUrl.indexOf('pcd')!==-1||item.fileUrl.indexOf('dxf')!==-1||item.fileUrl.indexOf('ufo')!==-1||item.fileUrl.indexOf('eps')!==-1||item.fileUrl.indexOf('ai')!==-1||item.fileUrl.indexOf('raw')!==-1||item.fileUrl.indexOf('WMF')!==-1||item.fileUrl.indexOf('webp')!==-1||item.fileUrl.indexOf('avif')!==-1" :src="item.fileUrl" alt="" style="width:500px;height:500px;">
                  </div>
                </a-carousel> -->
              </a-modal>
          </div>
        </s-table>
      </div>
      <!-- 任务详情 -->
      <div v-show="type === '3'">
        <a-form class="detail-form" v-if="detailInfo">
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="任务名称">
            <span>{{detailInfo.title}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="任务类别">
            <span>{{raskType.industryName}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="结算类型">
            <span>{{detailInfo.payMode==='10'?'单次':detailInfo.payMode==='20'?'多次':'--'}}</span>
          </a-form-item>
          <!-- <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="开票类目">
            <span v-if="ticketContentList.length>0">
              <span v-for="(item,index) in ticketContentList" :key="index">{{item.invoiceName}} <span v-show="index < ticketContentList.length-1">、</span> </span>
            </span>
          </a-form-item> -->
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="报名时间">
            <span>{{detailInfo.enrollBeginDate}}至{{detailInfo.enrollEndDate}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="任务时间">
            <span>{{detailInfo.workBeginDate}}至{{detailInfo.workEndDate}}</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="需要人数">
            <span>{{detailInfo.needNum}}人</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="预算金额">
            <span>{{detailInfo.budgetAmount.toFixed(2)}}元</span>
          </a-form-item>
          <a-form-item :labelCol="{lg: {span: 2}, sm: {span: 7}}" label="任务描述">
            <span>{{detailInfo.content}}</span>
          </a-form-item>
        </a-form>
      </div>
      <div v-show="type==='4'">
        
      </div>
    </section>
  </section>
</template>
<script>
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import Vue from 'vue'
  import {
    taskConfirmOrderList,
    getTaskResult,
    taskQueryById,
    getTaskType,
    getInvoiceByIndustryId
  } from '@/api/api';
  export default {
    components: {
      AInput,
      STable
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer'
      };
      return {
        visibleRask: false,
        visiblePicture: false,
        picList: [],
        searchParams: {
          signStatus: undefined,
          workStatus: undefined
        },
        searchParamsJg: {
          signStatus: undefined,
          workStatus: undefined
        },
        form: this.$form.createForm(this, { name: 'fapiao' }),
        type: '3',
        tableColumnsBm: [
          {
            title: '工作者姓名',
            dataIndex: 'userName',
            align: 'center',
            customRender: text => text===null?'--':text,
          },
          {
            title: '联系电话',
            dataIndex: 'phoneNo',
            align: 'center',
            customRender: text => text===null?'--':text,
          },
          {
            title: '自我介绍',
            dataIndex: 'oneselfRemark',
            align: 'center',
            customCell: () => {
                return {
                  style: cellStyle
                }
              },
              customRender: (text) => <a-tooltip placement="left" title={text}>{text}</a-tooltip>
          },
          {
            title: '首付款金额',
            dataIndex: 'advanceAmount',
            align: 'center',
            customRender: text =>text===null?'--': text.toFixed(2)
          },
          {
            title: '尾款金额',
            dataIndex: 'balanceAmount',
            align: 'center',
            customRender: text =>text===null?'--': text.toFixed(2)
          },
          {
            title: '实际收入金额',
            dataIndex: 'signAmount',
            align: 'center',
            customRender: text =>text===null?'--': text.toFixed(2)
          },
          {
            title: '签约状态',
            dataIndex: 'signStatus',
            align: 'center',
            customRender: text => {
              if (text === '10') {
                return '未确认'
              }
              if (text === '20') {
                return '企业已确认'
              }
              if (text === '30') {
                return '用户已签约'
              }
              if (text === '91') {
                return '取消确认-工作者取消报名'
              }
              if (text === '92') {
                return '确认失败-企业拒绝（不合适）'
              }
              if (text === '93') {
                return '拒绝签约失败-工作者拒绝'
              }
              if (text === '94') {
                return '取消签约失败-在未同意的情况下取消签约（收回签约）'
              }
              if (text === '95') {
                return '超时签约失败-超时系统自动签约失败'
              }
              if (text === '96') {
                return '签约失败-企业取消任务'
              }
            }
          },
          {
            title: '结算类型',
            dataIndex: 'payMode',
            align: 'center',
            customRender: text => {
              if (text === '10') {
                return '单次'
              }
              if (text === '20') {
                return '多次'
              }
              return '--'
            }
          },
          {
            title: '工作状态',
            dataIndex: 'workStatus',
            align: 'center',
            customRender: text => {
              if (text === '10') {
                return '未开始'
              }
              if (text === '20') {
                return '已工作中-未提交任务结果确认'
              }
              if (text === '21') {
                return '工作中-已提交任务结果'
              }
              if (text === '22') {
                return '工作中-以审核通过'
              }
              if (text === '23') {
                return '工作中-以审核不通过'
              }
              if (text === '30') {
                return '工作完成'
              }
            }
          },
          {
            title: '工作日期',
            dataIndex: 'workDate',
            align: 'center',
          },
          {
            title: '工作结束日期',
            dataIndex: 'workEndDate',
            align: 'center',
          }
        ],
        tableColumnsJg: [
          {
            title: '提交时间',
            dataIndex: 'createTime',
            width: '120px',
            align: 'center'
          },
          {
            title: '姓名',
            dataIndex: 'userName',
            customRender: text => text===null?'--':text,
            width: '80px',
            align: 'center'
          },
          {
            title: '联系电话',
            dataIndex: 'phoneNo',
            customRender: text => text===null?'--':text,
            width: '100px',
            align: 'center'
          },
          {
            title: '任务结果',
            dataIndex: 'items',
            scopedSlots: { customRender: 'items' },
            width: '100px',
            align: 'center'
          },
          {
            title: '任务结果描述',
            dataIndex: 'content',
            customRender: text => text===null?'--':text,
            width: '150px',
            align: 'center',
            customCell: () => {
                return {
                  style: cellStyle
                }
              },
              customRender: (text) => <a-tooltip placement="left" title={text}>{text}</a-tooltip>
          },
          {
            title: '审核状态',
            dataIndex: 'auditStatus',
            customRender: text => text === '10'?"待审核":text === '20'?"已通过":"已驳回",
            width: '100px',
            align: 'center'
          },
          {
            title: '驳回原因',
            dataIndex: 'errorInfo',
            customRender: text => text===null?'--':text,
            width: '100px',
            align: 'center'
          },
          {
            title: '处理时间',
            dataIndex: 'auditTime',
            width: '120px',
            align: 'center'
          },
        ],
        detailInfo: '',
        raskType: '',
        ticketContentList: [],
      }
    },
    props: {
      id: {
        type: Object,
        required: false
      },
    },
    created() {
      this.taskQueryById();
    },
    methods: {
      // 获取发票类目
      getTicketType (value) {
        getInvoiceByIndustryId(value).then(res => {
          if (res.success) {
            this.ticketContentList = res.result
          }
        })
      },
      // 获取任务详情
      taskQueryById () {
        taskQueryById({id:this.id.id}).then(res => {
          if (res.success) {
            this.detailInfo = res.result
            getTaskType(this.id.taskType).then(res=>{
              if (res.success) {
                this.raskType = res.result
                // this.getTicketType(res.result.id)
              }
            })
          }
        })
      },
      // 选择菜单
      handleMenuClick(e) {
        this.type = e.key;
        if (e.key==='4') {
          this.$router.push({name: 'financeManagement',params:{taskId: this.detailInfo.id}})
        }
      },
      // 获取工作者名单
      loadAccountListBm(parameter) {
        return taskConfirmOrderList(Object.assign(parameter,this.searchParams, {taskId: this.id.id}))
          .then(res => {
            return res.result
          })
      },
      // 获取任务结果
      loadAccountListJg(parameter) {
        return getTaskResult(Object.assign(parameter,this.searchParams, {taskId: this.id.id}))
          .then(res => {
            // // console.log('获取任务结果',res)
            return res.result
          })
      },
      // 查询1
      handleToSearchScope () {
        this.$refs.table.refresh(true);
      },
      // 重置1
      resetSearchScope () {
        this.searchParams = {
          signStatus: undefined,
          workStatus: undefined
        }
        this.handleToSearchScope()
      },
      // 查询2
      handleToSearchScopeJg () {
        this.$refs.tableJg.refresh(true);
      },
      // 重置2
      resetSearchScopeJg () {
        this.searchParamsJg = {
          signStatus: undefined,
          workStatus: undefined
        }
        this.handleToSearchScopeJg()
      },
      // 查看图片
      showPic(record) {
        this.picList = record.items
        this.visiblePicture=true
      },
    }
  }
</script>
<style lang="less">
  .business-detail {
    height: 100%;
    display: flex;
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
      border-right: 2px solid #1890ff;
    }
    .detail-content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      .detail-form {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .config-form {
        .ant-form-item-control {
          height: 40px;
        }
      }
      .taxList {
        .ant-empty-normal {
          margin: 0;
        }
      }
    }
  }
</style>
<style lang="less" scoped>
.pic-select{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
  }
}
.picture-common{
  width: 200px;
}
</style>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 500px;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 1);
  border-radius: 50%;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> .slick-dots{
  display: none !important;
}
</style>
