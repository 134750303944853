<!--
 * @Description: 任务管理
 * @Author: HuangCheng
 * @Date: 2020-03-12 10:46:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-23 15:56:44
 -->
<template>
  <div style="padding:24px;">
    <commonTitle title="任务管理" />

    <div class="table-page-search-wrapper" style="margin-bottom: 20px">
      <a-form layout="inline">
        <a-row :gutter="2" style="margin-bottom: 20px">
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="任务名称">
              <a-input
                v-model="searchForm.title"
                placeholder="请输入任务名称"
                style="width:230px"
              />
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="企业名称">
              <a-input
                v-model="searchForm.enterpriseName"
                placeholder="请输入企业名称"
                style="width:230px"
              />
            </a-form-item>
          </a-col>

          <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="任务状态">
              <a-select
                style="width: 230px"
                defaultValue=""
                placeholder="请选择状态"
                v-model="searchForm.status"
                
              >
                <!-- <a-select-option value="10">待审核</a-select-option>
                <a-select-option value="11">审核失败</a-select-option> -->
                <a-select-option value="13">报名中</a-select-option>
                <a-select-option value="14">报名截止</a-select-option>
                <a-select-option value="20">任务进行中</a-select-option>
                <a-select-option value="30">任务已完成</a-select-option>
                <a-select-option value="40">已结算</a-select-option>
                <a-select-option value="50">取消任务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="12">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="date"
                @change="handleDateChange"
                style="width: 230px"
              />
            </a-form-item>
          </a-col> -->
        </a-row>

        
      </a-form>
    </div>
    <div
          style="
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right:24px;
          "
        >
          <span></span>
          <span class="table-page-search-submitButtons">
            <a-button type="primary" icon="search" @click="handleToSearchList"
              >查询</a-button
            >
            <a-button
              style="margin-left: 8px"
              icon="reload"
              @click="resetSearchForm"
              >重置</a-button
            >
            <a-button
              type="primary"
              icon="plus"
              @click="createTaskClick"
              style="margin-left: 8px"
              >新建任务</a-button
            >
          </span>
        </div>
    <div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="tableColumns"
        :data="loadAccountList"
        bordered
      >
        <span slot="action" slot-scope="text, record">
          <a @click="checkDetail(record)">查看详情</a>
        </span>
      </s-table>
    </div>
    <a-modal
      title="任务详情"
      :visible="visibleDetail"
      @cancel="handleCancel"
      width="100%"
      class="detail-modal"
      :footer="null"
      :dialog-style="{ top: '0px', height: '100%' }"
    >
      <Detail
        @refresh="handleToSearchList"
        v-if="visibleDetail"
        :id="currentId"
      />
    </a-modal>
  </div>
</template>
<script>
import STable from "@/components/table/";
import AInput from "ant-design-vue/es/input/Input";
import Detail from "./components/detail.vue";
import commonTitle from "../components/commonTitle";
import {
  // taskOrderList
  getTaskChanneList,
} from "@/api/api";

export default {
  name: "BusinessInvoice",
  components: {
    AInput,
    STable,
    Detail,
    commonTitle,
  },
  data() {
    const cellStyle = {
      maxWidth: "120px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      cursor: "pointer",
    };
    return {
      searchForm: {
        title: "",
        createStartTime: "",
        createFinishTime: "",
        status: undefined,
        enterpriseName: "",
      },
      date: [],
      tableColumns: [
        {
          title: "企业名称",
          dataIndex: "enterpriseName",
          align: "center",
          width: "180px",
        },
        {
          title: "任务名称",
          dataIndex: "title",
          align: "center",
          width: "180px",
        },

        {
          title: "付款总额",
          dataIndex: "payTotalAmount",
          align: "center",
          width: "100px",
          customRender: (text) => (text === null ? "--" : text.toFixed(2)),
        },
        {
          title: "费用金额",
          dataIndex: "payFeeAmount",
          align: "center",
          width: "100px",
          customRender: (text) => (text === null ? "--" : text.toFixed(2)),
        },
        {
          title: "报名人数",
          dataIndex: "applyNum",
          align: "center",
          width: "100px",
        },
        {
          title: "签约人数",
          dataIndex: "signNum",
          align: "center",
          width: "100px",
        },

        {
          title: "任务状态",
          dataIndex: "status",
          align: "center",
          width: "130px",
          customRender: (text) => {
            if (text === "10") {
              return "待审核";
            }
            if (text === "11") {
              return "审核失败";
            }
            if (text === "13") {
              return "报名中";
            }
            if (text === "14") {
              return "报名截止";
            }
            if (text === "20") {
              return "任务进行中";
            }
            if (text === "30") {
              return "任务已完成";
            }
            if (text === "40") {
              return "已结算";
            }
            if (text === "50") {
              return "取消任务";
            }
          },
        },
        
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "150px",
          scopedSlots: { customRender: "action" },
        },
      ],
      form: this.$form.createForm(this, { name: "fapiao" }),
      record: "",
      visibleDetail: false,
      confirmLoading: false,
      currentId: "",
    };
  },
  methods: {
    //新建任务
    createTaskClick() {
      window.localStorage.setItem("submenu", "addTask");
      this.$router.push("/addTask");
    },
    // 选择时间段
    handleDateChange(date, dateString) {
      this.searchForm.createStartTime = `${dateString[0]} 00:00:00`;
      this.searchForm.createFinishTime = `${dateString[1]} 23:59:59`;
    },
    // 加载列表数据
    loadAccountList(parameter) {
      return getTaskChanneList(Object.assign(parameter, this.searchForm)).then(
        (res) => {
          // console.log('-->',res)
          return res.result;
        }
      );
    },
    // 查看详情
    checkDetail(record) {
      // this.currentId = record;
      // this.visibleDetail = true;

      // window.sessionStorage.setItem('raskId',this.taskId)
      console.log(record);
      window.localStorage.setItem("submenu", "taskDetail");
      this.$router.push("/taskDetail/" + record.id);
      // window.localStorage.setItem('submenu','payDetail')
      // this.$router.push('/payDetail')
    },
    handleCancel() {
      this.visibleDetail = false;
    },
    // 查询企业发票列表
    handleToSearchList() {
      this.$refs.table.refresh(true);
    },
    // 重置搜索条件
    resetSearchForm() {
      this.searchForm = {
        createStartTime: "",
        createFinishTime: "",
        title: "",
        status: undefined,
        enterpriseName: "",
      };
      this.date = [];
      this.handleToSearchList();
    },
  },
};
</script>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-body {
      height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>